import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateScore = ({ data, open, close,successMsg }) => {
  console.log(data, open);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    score: data ? data.score : "",
    user_id: data ? data.user_id : "",
    admin_id: sessionStorage.getItem("admin_id"),
    updated_at:moment().format("YYYY-MM-DD HH:mm:ss") 
  });

  const [rating, setRating] = useState(0);

  const handleRatingClick = (index) => {
    const score = index;
    setRating(index);
    setFormData({ ...formData, score });
  };

  const handleSubmit = () => {
    if (rating < 0 || rating > 10) {
      toast.warning("Please select a score between 0 and 10.", {
        autoClose: 3000,
      });
      return;
    }

    axios
      .put(
        `https://forex-expo-server.xicsolutions.in/score/updateUserScore/${data.score_id}`,
        formData
      )
      .then(() => {
        toast.success("Successfully updated score", { autoClose: 3000 });
        setTimeout(() => { successMsg();}, 3000);
       
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { autoClose: 3000 });
        } else {
          toast.error("Error updating score", { autoClose: 3000 });
        }
        console.error("Error updating score:", err);
      });
  };

  const getButtonColor = (index) => {
    if (index <= 6) return "#FF4C4C";
    if (index === 7 || index === 8) return "#FFDD57";
    if (index >= 9) return "#32CD32";
    return "#ccc";
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
          padding: "20px",
          background: "linear-gradient(145deg, #f0f0f0, #d9d9d9)",
          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        style={{
          textAlign: "center",
          color: "#3c3c3c",
          fontWeight: "bold",
          letterSpacing: "1px",
          fontSize: "24px",
        }}
      >
        Update Score
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#3c3c3c",
          }}
        >
          Score: {formData.score || "Not selected"}
        </div>

        <div className="button-rating mt-5" style={{ textAlign: "center" }}>
          {[...Array(11)].map((_, index) => (
            <Button
              key={index}
              onClick={() => handleRatingClick(index)}
              style={{
                backgroundColor: getButtonColor(index),
                color: "#fff",
                margin: "0 5px",
                padding: "10px 20px",
                borderRadius: "10px",
                fontWeight: rating === index ? "bold" : "normal",
              }}
            >
              {index}
            </Button>
          ))}
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={close}
          variant="outlined"
          color="secondary"
          style={cancelButtonStyle}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          style={submitButtonStyle}
        >
          Submit
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};

const cancelButtonStyle = {
  backgroundColor: "#f0f0f0",
  color: "#333",
  borderRadius: "20px",
  padding: "10px 20px",
  fontWeight: "bold",
  border: "1px solid #ccc",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
};

const submitButtonStyle = {
  backgroundColor: "#6000d2",
  color: "#fff",
  borderRadius: "20px",
  padding: "10px 20px",
  fontWeight: "bold",
  oxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
};

export default UpdateScore;
