import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import forex_logo from '../Images/Logos/white-logo.svg';
import kama_logo from '../Images/Logos/kama-capital-logo.png';
import './NavigationBar.css';

const NavigationBar = () => {
 
  return (
    <>
      <Navbar variant="light" className="navigation-bar">
        <Container>
          <Navbar.Brand href="#">
            <img
              src={kama_logo}
              alt="Left Image"
              className="d-inline-block align-top kama-logo"
            />
          </Navbar.Brand>

          {/* Right Side - FOREX Logo and Logout Icon */}
          <div className="ms-auto d-flex align-items-center">
            <Navbar.Brand href="#">
              <img
                src={forex_logo}
                alt="Right Image"
                className="d-inline-block align-top forex-logo"
              />
            </Navbar.Brand>

          </div>
        </Container>
      </Navbar>

      
    </>
  );
};

export default NavigationBar;
