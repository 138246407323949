import React, { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import './Confetti.css'; // Import the CSS file
import moment from 'moment';
import winningMusic from '../Images/leader-board/winner-bgm-1-new.mp3';

const Confetti = () => {
    const [windowDimension, setDimension] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [showConfetti, setShowConfetti] = useState(false);

    // Detect window size changes
    const detectSize = () => {
        setDimension({ width: window.innerWidth, height: window.innerHeight });
    };

    useEffect(() => {
        window.addEventListener('resize', detectSize);
        return () => {
            window.removeEventListener('resize', detectSize);
        };
    }, []);

    // Check for the exact time to trigger the confetti and sound
    useEffect(() => {
        const checkTime = () => {
            const currentTime = moment();
            const targetTime = moment().set({ hour: 18, minute: 0, second: 0 }); // Example: 5:32 PM
            if (currentTime.isSame(targetTime, 'minute')) {
                setShowConfetti(true);
                playWinningAudio();
            }
        };

        const intervalId = setInterval(checkTime, 1000); // Check every second
        return () => clearInterval(intervalId); // Cleanup
    }, []);

    // Hide confetti after 30 seconds
    useEffect(() => {
        let timeoutId;
        if (showConfetti) {
            timeoutId = setTimeout(() => {
                setShowConfetti(false);
            }, 60000); // Hide after 30 seconds
        }
        return () => clearTimeout(timeoutId);
    }, [showConfetti]);

    // Play the winning audio
    const playWinningAudio = () => {
        const audio = new Audio(winningMusic);
        audio.play().catch((error) => {
            console.log('Audio playback failed', error);
        });
    };

    return (
        <div className="announcement-container">
            {showConfetti && (
                <>
                    <ReactConfetti
                        width={windowDimension.width}
                        height={windowDimension.height}
                        tweenDuration={1000}
                    />
                    <div className="winning-text mt-3">Congratulations!</div>
                </>
            )}
        </div>
    );
};

export default Confetti;
