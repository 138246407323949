import React, { useCallback, useEffect, useState , useRef} from "react";
import countryData from '../../country.json'
import { Container } from "react-bootstrap";
import Webcam from "react-webcam";
import { FaCamera, FaUpload } from "react-icons/fa";
import SignaturePad from "signature_pad";
import { Button, Dialog, DialogActions, DialogContent, Link } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions"
import "./RegistrationForm.css"
import moment from "moment";
import userImgAvatar from '../Images/user-img-avatar.jpg'

const RegistrationForm = ({registerAdminId}) => {
  
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    country: "",
    countrycode: "",
    user_phone: "",
    user_image: null,
    signature: null,
    recaptcha: "",
    created_at: moment().format("YYYY-MM-DD HH:mm:ss")
  });

  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const webcamRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [openCondition, setOpenCondition] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (formData.country) {
      const countryName = countryData.find(c_name => c_name.name === formData.country);
      setFormData({ ...formData, countrycode: countryName ? countryName.dial_code : "" });
    } else {
      setFormData({ ...formData, countrycode: "" });
    }
  }, [formData.country]);

  const handleValidation = (name, value) => {
    let errmsg = "";
    const trimmedValue = value && typeof value === 'string' ? value.trim() : value;
    switch (name) {
      case "user_name":
        if (!trimmedValue) {
          errmsg = "User Name is Required.";
        }
        break;
      case "user_email":
        if (!trimmedValue) {
          errmsg = "User Email Id is Required.";
        } else if (!/\S+@\S+\.\S+/.test(trimmedValue)) {
          errmsg = "Email format is invalid.";
        }
        break;
      case "country":
        if (!trimmedValue) {
          errmsg = "Country is Required.";
        }
        break;
      case "user_phone":
        if (!trimmedValue) {
          errmsg = "Mobile Number is Required.";
        } else if (!/^\d+$/.test(trimmedValue)) {
          errmsg = "Phone number should only contain digits.";
        }
        break;
      case "signature":
        if (!signaturePadRef.current || signaturePadRef.current.isEmpty()) {
          errmsg = "Signature is Required.";
        }
        break;
      case "recaptcha":
        if (!value) {
          errmsg = "Recaptcha is Required.";
        }
        break;
      default:
        break;
    }
    return errmsg;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const error = handleValidation(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const validateForm = () => {
    let formErrors = {};
    Object.keys(formData).forEach((name) => {
      const error = handleValidation(name, formData[name]);
      if (error) {
        formErrors[name] = error;
      }
    });

    // Check if signature is required
    if (isCheckboxChecked && (!signaturePadRef.current || signaturePadRef.current.isEmpty())) {
      formErrors["signature"] = "Signature is required.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPreviewImage(imageSrc);
    setCameraEnabled(false);

    const stream = webcamRef.current.video.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setErrors(prevErrors => ({ ...prevErrors, user_image: null }));
  }, [webcamRef]);

  const enableCamera = () => {
    setCameraEnabled(true);
    setErrors(prevErrors => ({ ...prevErrors, user_image: null }));
  };

  const handleRetake = () => {
    setPreviewImage(null);
    enableCamera();
  };

  const handleTake = () => {
    capture();
    setErrors(prevErrors => ({ ...prevErrors, user_image: null }));
  };

  const base64ToFile = (base64String, fileName) => {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], fileName, { type: mimeString });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileSelected(file);
      setPreviewImage(URL.createObjectURL(file));
      setErrors(prevErrors => ({ ...prevErrors, user_image: null }));
    }
  };
  
  const handleClear = () => {
    signaturePadRef.current.clear();
    setSignatureDataURL(null);
    setErrors(prevErrors => ({ ...prevErrors, signature: null }));
  };

  useEffect(() => {
    if (isCheckboxChecked) {
      const canvas = canvasRef.current;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      const context = canvas.getContext("2d");
      context.scale(ratio, ratio);

      signaturePadRef.current = new SignaturePad(canvas);
    }
  }, [isCheckboxChecked]);


  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setFormData(prevFormData => ({ ...prevFormData, recaptcha: token }));
    setErrors((prevErrors) => ({ ...prevErrors, recaptcha: null }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form data before submission
    if (!validateForm()) {
      return;
    }

    const data = new FormData();
     // If no image is provided, use default avatar
  if (!fileSelected && !previewImage) {
    const defaultAvatarFile = base64ToFile(userImgAvatar, "default_avatar.jpg");
    data.append("user_image", defaultAvatarFile);
  } else if (fileSelected) {
    data.append("user_image", fileSelected);
  } else if (previewImage) {
    const name = formData.user_name || "selfie";
    const selfieFile = base64ToFile(previewImage, `${name}.jpg`);
    data.append("user_image", selfieFile);
  }


    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const signatureDataURL = signaturePadRef.current.toDataURL();
      const name = formData.user_name || 'signature';
      const signatureFile = base64ToFile(signatureDataURL, `${name}.png`);
      data.append("signature", signatureFile);
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, signature: "Signature is required." }));
      return;
    }


    {registerAdminId && (
      data.append('admin_id',registerAdminId)
    )}

    Object.keys(formData).forEach((key) => {
      if (key !== 'user_image' && key !== 'signature') {
        data.append(key, formData[key]);
      }
    });

    axios.post(
      'https://forex-expo-server.xicsolutions.in/user/storeUserData',
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      console.log("User Data submitted successfully.", res.data);
      navigate(`/uniqueId/${res.data.unique_id}`);
    })
    .catch((err) => {
      if(err.response){
        toast.error(err.response.data.message,{autoClose:3000})
      }
      console.error("Error:", err);
    });
  };
  
  

  return (

      <Container>
        <ToastContainer/>
        <div className="reg-Register-form-contain">
          <div className="reg-content">
            <div className="reg-glass-container">
              <h1>Register Now</h1>
              <form id="glass-registration-form" onSubmit={handleSubmit}>
                <div className="reg-glass-form-group reg-full-width-full-name">
                  <label htmlFor="user_name">Full Name <span style={{color:'red',fontSize:'22px'}} >*</span> </label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    value={formData.user_name}
                    onChange={handleInputChange}
                  />
                  {errors.user_name && <label style={{ color: 'red' }}>{errors.user_name}</label>}
                </div>
                <div className="reg-glass-form-group">
                  <label htmlFor="user_email">Email <span style={{color:'red',fontSize:'22px'}} >*</span> </label>
                  <input
                    type="email"
                    id="user_email"
                    name="user_email"
                    value={formData.user_email}
                    onChange={handleInputChange}
                  />
                  {errors.user_email && <label style={{color:'red'}}>{errors.user_email}</label>}
                </div>
                <div className="reg-glass-form-group">
                  <label htmlFor="country">Country <span style={{color:'red',fontSize:'22px'}} >*</span> </label>
                  <select
                      id="country"
                      name="country"
                      className="country-name-input"
                      value={formData.country}
                      onChange={handleInputChange}
                    >
                      <option value="">Please select country</option>
                      {countryData.map((country) => (
                        <option value={country.name}>{country.name}</option>
                      ))}
                    </select>
                    {errors.country && <label style={{color:'red'}}>{errors.country}</label>}
                </div>
                <div className="reg-glass-form-group">
                  <label htmlFor="user_phone">Phone Number <span style={{color:'red',fontSize:'22px'}} >*</span> </label>
                  <div className="reg-phone-input-group">
                    <select
                      id="country_code"
                      name="country_code"
                      value={formData.countrycode}
                      onChange={handleInputChange}
                      disabled
                    >
                      <option value="">code</option>
                      {countryData.map((code) => (
                        <option value={code.dial_code}>{code.dial_code}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id="user_phone"
                      name="user_phone"
                      value={formData.user_phone}
                      onChange={handleInputChange}
                    />
                    
                  </div>
                  {errors.user_phone && <label style={{color:'red'}}>{errors.user_phone}</label>}
                </div>
                <div className="reg-glass-form-group reg-one">
                  <label htmlFor="preview_image" >Preview Image</label>
                  <div className="reg-preview-container">
                    {!previewImage && !cameraEnabled && (
                      <div className="reg-placeholder-text" id="preview_image-container">
                        <p>Your preview photo will be shown here</p>
                      </div>
                    )}
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="reg-preview-image-display"
                      />
                    )}
                    {cameraEnabled && !previewImage && (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover", borderRadius: "10px" }}
                        screenshotQuality={1}
                        mirrored={true}
                        imageSmoothing={true}
                      />
                    )}
                    {previewImage && (
                      <button
                        type="button"
                        className="reg-retake-button"
                        onClick={handleRetake}
                        name="user_image"
                      >
                        Retake Photo
                      </button>
                    )}
                    {cameraEnabled && !previewImage && (
                      <button
                        type="button"
                        className="reg-capture-button"
                        onClick={handleTake}
                      >
                        Capture
                      </button>
                    )}
                  </div>
                  {errors.user_image && <label style={{color:'red'}}>{errors.user_image}</label>}
                  
                </div>
                <div className="reg-glass-form-group reg-two">
                  <label htmlFor="user_image">Profile Image</label>
                  <div className="reg-user_image_input">
                    <div className="reg-upload_image reg-selfie_upload">
                      <div
                        className="reg-camera_button"
                        onClick={enableCamera}
                      >
                        <FaCamera className="reg-icon reg-camera-icon" />
                        <p >Click an image</p>
                      </div>
                    </div>
                    <div className="reg-upload_image reg-file_upload">
                      <input
                        type="file"
                        ref={fileInputRef}
                        id="file-upload"
                        className="reg-file-upload-input"
                        accept="image/*"
                        onChange={handleFileUpload}
                        name="file-upload"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="file-upload" className="reg-file-upload-label">
                        <FaUpload className="reg-icon reg-upload-icon" />
                        <p>Upload a file</p>
                      </label>

                    </div>
                  </div>
                </div>
                <div className="reg-four mt-5 mt-md-0">
                <div >
                  <div className="reg-form-terms-condition-signature-container">
                  <label className="me-2 ms-md-0 ms-2">
                    <input
                      className="agree-check-box"
                      type="checkbox"
                      id="termsCheckbox"

                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                    />
                  </label>
                  <Link component="button" variant="body2" onClick={() => setOpenCondition(true)} className="termslink ms-3" style={{ marginTop: "-7px", }}>By signing on the below, I agree to the  Terms  & Conditions  <span style={{color:'red',fontSize:'22px'}} >*</span></Link>
                  
                  </div>
                  <div className="reg-signature">
                    <div className="reg-signaturePad">
                      {isCheckboxChecked && (
                        <div className="reg-signature-pad">
                          <canvas ref={canvasRef} className="reg-signature-canvas" ></canvas>
                          <button type="button" className="reg-signature-clear-button" onClick={handleClear}>
                            Clear Signature
                          </button>
                          {errors.signature_pad && <label style={{color:'red'}}>{errors.signature_pad}</label>}
                        </div>
                      )}
                      
                    </div>
                  </div>
                  
                  </div>
                  <ReCAPTCHA
                  sitekey="6LcAt1cqAAAAAKCHFky8lmz-QS5lOqm5OyLJGUAg"
                  onChange={handleRecaptchaChange}
                /> 
                {errors.recaptcha && <span style={{color:'red'}}>{errors.recaptcha}</span>}
                  <button type="submit" className="reg-registeration-submit-button mt-4" >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Dialog open={openCondition} onClose={()=>setOpenCondition(false)} 
            maxWidth='md'>
        <DialogContent className="reg-form-terms-and-conditions-dialogbox-con">
          <TermsAndConditions/>
        </DialogContent>
        <DialogActions className="reg-form-terms-and-conditions-dialogbox-con reg-form-dialog-close">
          <button onClick={()=>setOpenCondition(false)} className="reg-registeration-terms-close-btn">Close</button>
        </DialogActions>
      </Dialog>
      </Container >
  )
};

export default RegistrationForm;
