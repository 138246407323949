import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./RegisterationFooter.css";
import kamaLogo from "../Images/logo.png";
import forexLogo from "../Images/Logos/white-logo.svg";

function RegisterationFooter() {
    return (
        <div className="reg-footer mt-5">
            
                <Row className="reg-footer-content">
                    <Col xs={6} md={6} sm={6} lg={6} className="footer-item d-flex align-items-center justify-content-start mb-3 mb-md-0">
                        <img src={kamaLogo} alt="Kama Capital Logo" className="reg-footer-logo" />
                    <a href="https://kama-capital.com/" target="_blank" rel="noopener noreferrer">
                        www.kama-capital.com
                        </a>
                    </Col>
                    <Col xs={6} md={6} sm={6} lg={6} className="footer-item d-flex align-items-center justify-content-end mb-3 mb-md-0">
                        <img src={forexLogo} alt="IFX Expo Logo" className="reg-footer-logo" />
                    <a href="https://theforexexpo.com/" target="_blank" rel="noopener noreferrer">
                            www.theforexexpo.com
                        </a>
                    </Col>
                </Row>
            
        </div>
    );
}

export default RegisterationFooter;
