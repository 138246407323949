import React, { useEffect, useRef, useState } from 'react'
import NavigationBar from '../NavigationBar/NavigationBar'
import Gallary from '../Gallary/Gallary'
import RegisterationFooter from '../RegisterationFooter/RegisterationFooter'
import RegistrationStripe from '../RegistrationStripe/RegistrationStripe'
import RegistrationContact from '../RegistrationContact/RegistrationContact'
import { Col, Row,Modal, Button  } from 'react-bootstrap'
import { FiLogOut } from 'react-icons/fi';
import { FiHome } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom'
import RegistrationForm from './RegistrationForm'
import { useParams } from 'react-router-dom'
import giveawayRegVdo from '../Images/forex-give-away-reg-form-vdo.mp4'

const RegistrationFormRouting = () => {
  const vdoRef = useRef();
  const { adminId  } = useParams(); 
  const currentAdminId = adminId || sessionStorage.getItem('admin_id');
  const navigate = useNavigate();
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = '/'; 
  };

  const toggleLogoutModal = () => {
    setShowConfirmLogout(!showConfirmLogout);
  };

  const handleNavigate = () =>{
    navigate('/')
  }

  useEffect(()=>{
    if(vdoRef.current){
      vdoRef.current.play();
    }
  },[])

  return (
    <div style={{overflow:'hidden'}}>
      <NavigationBar/>
      {sessionStorage.getItem('login') == 'true' && (
        <Row className='mt-5'>
        <Col xs={6} className='d-flex justify-content-start'>
          <div className='logout-icon ms-5' onClick={handleNavigate}>
          <div className="d-flex justify-content-center">
          <FiHome   />
          </div>
          <h6 className='text-white'>Home</h6>
          </div>
          </Col>
          <Col xs={6} className='d-flex justify-content-end'>
          <div className='logout-icon me-5' onClick={toggleLogoutModal}>
          <div className="d-flex justify-content-center">
          <FiLogOut   />
          </div>
          <h6 className='text-white'>Logout</h6>
          </div>
          </Col>
        </Row>
      )}
      <div className='mt-5'>
        <h1 className='text-center fw-bold' style={{color:'#FEA701',fontWeight:'bold' }}>Access Global Markets with an Adaptive Fintech Institution</h1>
        {sessionStorage.getItem('login')== 'true' &&(
          <p className='text-md-center reg-page-about-paragraph text-white' style={{padding:'2rem',fontSize:'20px' }}>
          At KAMA Capital, we prioritize regulatory compliance, ensuring transparency and client protection. Our operations are authorized and regulated by reputable authorities, including the FSC of Mauritius and the regulatory body in St. Vincent and Grenadines. We also have a registered representative office in Dubai, UAE, regulated by the Dubai Economic Department. Rest assured, we maintain the highest standards of professionalism and integrity to safeguard our clients' investments with low spreads and commission.
          </p>
        )}
      </div>
     <div className='mt-5'>
     <RegistrationForm registerAdminId={currentAdminId} />
     </div>
     
      {sessionStorage.getItem('login') !== 'true' &&(
          <p className='text-center text-white mt-3' style={{paddingRight:'2em',paddingLeft:'2em',fontSize:'18px',marginTop:'-2em' }}>
          At KAMA Capital, we prioritize regulatory compliance, ensuring transparency and client protection. Our operations are authorized and regulated by reputable authorities, including the FSC of Mauritius and the regulatory body in St. Vincent and Grenadines. We also have a registered representative office in Dubai, UAE, regulated by the Dubai Economic Department. Rest assured, we maintain the highest standards of professionalism and integrity to safeguard our clients' investments with low spreads and commission.
          </p>
        )}
        <Row>
          <Col xs={12} className='d-flex justify-content-center mb-3 mb-md-5 mt-md-3'>
          <video
          ref={vdoRef}
            src={giveawayRegVdo}
            controls
            loop
            autoPlay
            muted
            className='give-away-reg-form-vdo'
          />
          </Col>
        </Row>
      <RegistrationStripe/>
      <Gallary/>
      <RegistrationContact/>
      <div className='mt-5'>
        <RegisterationFooter />
    </div>

    <Modal show={showConfirmLogout} onHide={toggleLogoutModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to log out?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleLogoutModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default RegistrationFormRouting
